import React from 'react';

export default function IndexSectionHeaders1() {
    return (
        <React.Fragment>
            <>
                <section className="relative">
  <img className="absolute top-0 left-0" src="flaro-assets/images/headers/gradient4.svg" alt />
  <div className="container mx-auto overflow-hidden">
    <div className="relative z-20 flex items-center justify-between px-4 py-5 bg-transparent">
      <div className="w-auto">
        <div className="flex flex-wrap items-center">
          <div className="flex items-center justify-between w-auto mr-14">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="M212.31-100Q182-100 161-121q-21-21-21-51.31v-535.38Q140-738 161-759q21-21 51.31-21h55.38v-84.61h61.54V-780h303.08v-84.61h60V-780h55.38Q778-780 799-759q21 21 21 51.31v535.38Q820-142 799-121q-21 21-51.31 21H212.31Zm0-60h535.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-375.38H200v375.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM200-607.69h560v-100q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H212.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v100Zm0 0V-720v112.31Zm280 210.77q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.04 0-14.69 10.34-25.04 10.35-10.34 25.04-10.34t25.04 10.34q10.34 10.35 10.34 25.04 0 14.7-10.34 25.04-10.35 10.35-25.04 10.35Zm-160 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.04 0-14.69 10.34-25.04 10.35-10.34 25.04-10.34t25.04 10.34q10.34 10.35 10.34 25.04 0 14.7-10.34 25.04-10.35 10.35-25.04 10.35Zm320 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.04 0-14.69 10.34-25.04 10.35-10.34 25.04-10.34t25.04 10.34q10.34 10.35 10.34 25.04 0 14.7-10.34 25.04-10.35 10.35-25.04 10.35ZM480-240q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.03 0-14.7 10.34-25.04 10.35-10.35 25.04-10.35t25.04 10.35q10.34 10.34 10.34 25.04 0 14.69-10.34 25.03Q494.69-240 480-240Zm-160 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.03 0-14.7 10.34-25.04 10.35-10.35 25.04-10.35t25.04 10.35q10.34 10.34 10.34 25.04 0 14.69-10.34 25.03Q334.69-240 320-240Zm320 0q-14.69 0-25.04-10.35-10.34-10.34-10.34-25.03 0-14.7 10.34-25.04 10.35-10.35 25.04-10.35t25.04 10.35q10.34 10.34 10.34 25.04 0 14.69-10.34 25.03Q654.69-240 640-240Z"/></svg>
          <a  class="inline-flex items-center h-7" className="text-lg font-semibold font-heading">
&nbsp;
            EveCal
        
        </a>
            
          </div>
          <div className="hidden w-auto lg:block">
           
          </div>
        </div>
      </div>
      <div className="w-auto">
        <div className="flex flex-wrap items-center">
          <div className="hidden w-auto mr-5 lg:block">
            <div className="inline-block">
            </div>
          </div>
          <div className="hidden w-auto lg:block">
            <div className="inline-block">
              <button className="w-full px-5 py-3 font-semibold text-white transition duration-200 ease-in-out bg-indigo-600 border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 hover:bg-indigo-700" type="button">Contact Us</button>
            </div>
          </div>
          <div className="w-auto lg:hidden">
            <button>
              <svg className="text-indigo-600" width={51} height={51} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={56} height={56} rx={28} fill="currentColor" />
                <path d="M37 32H19M37 24H19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="fixed top-0 bottom-0 left-0 z-50 hidden w-4/6 sm:max-w-xs">
      <div className="fixed inset-0 bg-gray-800 opacity-80" />
      <nav className="relative z-10 h-full pt-8 overflow-y-auto bg-white px-9">
        <div className="flex flex-wrap justify-between h-full">
          <div className="w-full">
            <div className="flex items-center justify-between -m-2">
              <div className="w-auto p-2">
                <a className="inline-block" href="#">
                  <img src="flaro-assets/logos/flaro-logo-black.svg" alt />
                </a>
              </div>
              <div className="w-auto p-2">
                <button>
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 18L18 6M6 6L18 18" stroke="#111827" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center w-full py-16">
           
          </div>
          <div className="flex flex-col justify-end w-full pb-8">
            <div className="flex flex-wrap">
              <div className="w-full mb-3">
                <div className="block">
                  <button className="w-full px-5 py-3 font-medium transition duration-200 ease-in-out bg-transparent hover:text-gray-700 rounded-xl" type="button">Sign In</button>
                </div>
              </div>
              <div className="w-full">
                <div className="block">
                  <button className="w-full px-5 py-3 font-semibold text-white transition duration-200 ease-in-out bg-indigo-600 border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 hover:bg-indigo-700" type="button">Contact Us</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div className="pt-16 pb-48 overflow-hidden">
    <div className="container relative px-4 mx-auto">
      <div className="flex flex-wrap -m-8">
        <div className="w-full p-8 md:w-1/2 lg:w-4/12 xl:w-6/12">
          <h1 className="text-6xl font-bold leading-none mb-9 md:text-8xl lg:text-13xl font-heading md:max-w-2xl">Say Goodbye to No-Shows</h1>
          <div>
            <p className="text-xl font-medium text-gray-900 mb-9 md:max-w-sm">EveCal integrates seamlessly into your customers' lives, making scheduling and communication easier than ever.</p>
            <div className="mb-12 md:inline-block">
              <button className="w-full px-6 py-4 font-semibold text-white transition duration-200 ease-in-out bg-indigo-600 border border-indigo-700 rounded-xl focus:ring focus:ring-indigo-300 hover:bg-indigo-700" type="button">Contact Us</button>
            </div>
           
          </div>
        </div>
        <div className="w-full p-8 md:w-1/2 lg:w-8/12 xl:w-8/12 xl:absolute xl:right-0 xl:-bottom-20">
          <div className="flex flex-wrap items-center justify-center -m-3 lg:justify-end">
            <div className="w-auto p-3 lg:w-1/3 xl:pt-28">
              <div className="flex flex-wrap justify-end">
                <div className="w-auto">
                  <img className="mx-auto transition duration-1000 ease-in-out transform rounded-xl hover:-translate-y-16" src="https://images.unsplash.com/photo-1493775379751-a6c3940f3cbc?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwzMnx8c2Fsb258ZW58MHwwfHx8MTcyMjYyNDQ0NXww&ixlib=rb-4.0.3&q=85&w=1920" alt />
                </div>
              </div>
            </div>
            <div className="w-auto p-3 lg:w-1/3">
              <div className="flex flex-wrap justify-center -m-3">
                <div className="w-auto p-3">
                  <a href="#">
                    <img className="mx-auto transition duration-1000 ease-in-out transform rounded-xl hover:-translate-y-16" src="https://images.unsplash.com/photo-1517130038641-a774d04afb3c?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwyNHx8Z3ltfGVufDB8MHx8fDE3MjI2MjQzMDZ8MA&ixlib=rb-4.0.3&q=85&w=1920" alt />
                  </a>
                </div>
                <div className="w-auto p-3">
                  <img className="mx-auto transition duration-1000 ease-in-out transform hover:-translate-y-16 rounded-xl" src="https://images.unsplash.com/photo-1581056771107-24ca5f033842?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwzNHx8ZG9jdG9yfGVufDB8MHx8fDE3MjI2MjQyMDV8MA&ixlib=rb-4.0.3&q=85&w=1920" alt />
                </div>
              </div>
            </div>
            <div className="w-auto p-3 lg:w-1/3">
              <div className="flex flex-wrap">
                <div className="w-auto">
                  <img className="mx-auto transition duration-1000 ease-in-out transform rounded-xl hover:-translate-y-16" src="https://images.unsplash.com/photo-1615906655593-ad0386982a0f?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHw0M3x8Y2FyJTIwZW5naW5lfGVufDB8MXx8fDE3MjI2MjQwMTZ8MA&ixlib=rb-4.0.3&q=85&w=1920" alt />
                </div>
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}


import React, { useState } from 'react';

const IndexSectionContact6 = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [notification, setNotification] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formElement = e.target;
    const formData = new FormData(formElement);
  
    try {
      const response = await fetch('/contact', {
        method: 'POST',
        body: formData
      });
  
      if (response.ok) {
        setNotification('Message received');
        formElement.reset(); // Reset the form fields
      } else {
        setNotification('Failed to send message');
      }
    } catch (error) {
      setNotification('Failed to send message');
    }
  };



    return (
       
                <section className="relative overflow-hidden py-36 bg-gray-50">
  <img className="absolute bottom-0 right-0" src="flaro-assets/images/contact/gradient2.svg" alt />
  <div className="container relative z-10 px-4 mx-auto">
    <div className="flex flex-wrap -m-8">
      <div className="w-full p-8 md:w-1/2">
        <div className="flex flex-col justify-between h-full">
          <div className="block mb-12 md:max-w-md">
            <p className="mb-6 text-sm font-bold text-indigo-600 uppercase tracking-px">Contact us</p>
            <h2 className="text-6xl font-bold leading-none md:text-8xl xl:text-10xl font-heading tracking-px-n">Get connected to grow better business.</h2>
          </div>
          <div className="block">
            
          </div>
        </div>
      </div>
      <div className="w-full p-8 md:w-1/2">
        <form enctype="multipart/form-data" onSubmit={handleSubmit} className="pt-8 pb-12 ml-auto bg-white px-11 bg-opacity-80 md:max-w-xl rounded-4xl shadow-12xl">
          <label className="block mb-4">
            <p className="mb-2 font-semibold leading-normal text-gray-900">Name</p>
            <div className="relative">
              <svg className="absolute transform -translate-y-1/2 left-4 top-1/2" width={20} height={26} viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3327 8.83358C13.3327 10.6745 11.8403 12.1669 9.99935 12.1669C8.1584 12.1669 6.66602 10.6745 6.66602 8.83358C6.66602 6.99263 8.1584 5.50024 9.99935 5.50024C11.8403 5.50024 13.3327 6.99263 13.3327 8.83358Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.99935 14.6669C6.77769 14.6669 4.16602 17.2786 4.16602 20.5002H15.8327C15.8327 17.2786 13.221 14.6669 9.99935 14.6669Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <input name="name"
            value={formData.name}
            onChange={handleChange} className="w-full py-3 pl-12 pr-4 font-medium text-gray-500 placeholder-gray-500 border border-gray-300 rounded-lg outline-none focus:ring focus:ring-indigo-300" id="contactInput3-1" type="text" placeholder="First & last name" />
            </div>
          </label>
          <label className="block mb-4">
            <p className="mb-2 font-semibold leading-normal text-gray-900">Email Address</p>
            <div className="relative">
              <svg className="absolute transform -translate-y-1/2 left-4 top-1/2" width={20} height={26} viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3327 8.83358C13.3327 10.6745 11.8403 12.1669 9.99935 12.1669C8.1584 12.1669 6.66602 10.6745 6.66602 8.83358C6.66602 6.99263 8.1584 5.50024 9.99935 5.50024C11.8403 5.50024 13.3327 6.99263 13.3327 8.83358Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.99935 14.6669C6.77769 14.6669 4.16602 17.2786 4.16602 20.5002H15.8327C15.8327 17.2786 13.221 14.6669 9.99935 14.6669Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <input type="email"
            name="email"
            value={formData.email}
            onChange={handleChange} className="w-full py-3 pl-12 pr-4 font-medium text-gray-500 placeholder-gray-500 border border-gray-300 rounded-lg outline-none focus:ring focus:ring-indigo-300" id="contactInput3-2" type="text" placeholder="Email address" />
            </div>
          </label>
          <label className="block">
            <p className="mb-2 font-semibold leading-normal text-gray-900">Message</p>
            <div className="relative">
              <svg className="absolute left-4 top-4" width={20} height={26} viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3327 8.83358C13.3327 10.6745 11.8403 12.1669 9.99935 12.1669C8.1584 12.1669 6.66602 10.6745 6.66602 8.83358C6.66602 6.99263 8.1584 5.50024 9.99935 5.50024C11.8403 5.50024 13.3327 6.99263 13.3327 8.83358Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.99935 14.6669C6.77769 14.6669 4.16602 17.2786 4.16602 20.5002H15.8327C15.8327 17.2786 13.221 14.6669 9.99935 14.6669Z" stroke="#9CA3AF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <textarea name="message"
            value={formData.message}
            onChange={handleChange} className="w-full h-48 p-4 px-12 mb-6 font-medium text-gray-500 placeholder-gray-500 border border-gray-300 rounded-lg outline-none resize-none focus:ring focus:ring-indigo-300" id="contactInput3-3" placeholder="Write message"  />
              <div className="md:inline-block">
                <button type="submit"  className="w-full py-4 font-semibold text-white transition duration-200 ease-in-out bg-indigo-600 border border-indigo-700 px-9 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 hover:bg-indigo-700" >Send Message</button>
              </div>
            </div>
          </label>

          {notification && (
        <div className="p-2 mb-2 font-semibold leading-normal text-gray-900">
          {notification}
        </div>
      )}
        </form>

       
      </div>
    </div>
  </div>
</section>


            
       
    );
}

export default IndexSectionContact6;


import React from 'react';

export default function IndexSectionFooters7() {
    return (
        <React.Fragment>
            <>
                <section className="pt-24 overflow-hidden bg-white">
  <div className="container px-4 mx-auto">
    <div className="pb-20 border-b">
      <div className="flex flex-wrap -m-8">
      </div>
    </div>
    <div className="flex flex-wrap items-center justify-between py-6 -m-4">
      <div className="w-auto p-4">
        
      </div>
      <div className="w-auto p-4">
        <p className="text-sm font-medium text-gray-600">Copyright ©  EveCal. All Rights Reserved</p>
      </div>
      <div className="w-auto p-4">
       
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}


import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeaders1 from '../components/headers/IndexSectionHeaders1';
import IndexSectionHowItWorks2 from '../components/how-it-works/IndexSectionHowItWorks2';
import IndexSectionFeatures3 from '../components/features/IndexSectionFeatures3';
import IndexSectionTestimonials4 from '../components/testimonials/IndexSectionTestimonials4';
import IndexSectionCta5 from '../components/cta/IndexSectionCta5';
import IndexSectionContact6 from '../components/contact/IndexSectionContact6';
import IndexSectionFooters7 from '../components/footers/IndexSectionFooters7';

const meta = {
  title: 'EveCal',
  meta: [
    { name: 'description', content: 'Say good bye to no-shows and hello to more appointments. EveCal is the best way to manage your appointments and bookings.' }
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: 'assets/favicon.svg' }
  ],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeaders1 />
      <IndexSectionHowItWorks2 />
      <IndexSectionFeatures3 />
      <IndexSectionTestimonials4 />
      <IndexSectionCta5 />
      <IndexSectionContact6 />
      <IndexSectionFooters7 />
    </React.Fragment>
  );
}


import React from 'react';

export default function IndexSectionFeatures3() {
    return (
        <React.Fragment>
            <>
            <section class="pt-32 pb-36 bg-indigo-600 overflow-hidden">
  <div class="container px-4 mx-auto">
    <div class="md:max-w-2xl">
      <h2 class="mb-9 text-6xl md:text-8xl xl:text-10xl text-white font-bold tracking-px-n leading-none">Revolutionizing Appointment Scheduling and Client Engagement</h2>
      
    </div>
    <div class="flex flex-wrap -m-3">
      <div class="w-full md:w-1/2 p-3">
        <div class="p-7 bg-white border border-gray-900 rounded-3xl">
          <div class="flex flex-wrap -m-4">
            <div class="w-auto p-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z" />
</svg>

            </div>
            <div class="flex-1 p-4">
              <h3 class="mb-3 text-lg font-semibold">Healthcare Providers</h3>
              <p class="text-gray-600 font-medium">Schedule patient appointments, follow-ups, and health checks with ease.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-3">
        <div class="p-7 bg-white border border-gray-900 rounded-3xl">
          <div class="flex flex-wrap -m-4">
            <div class="w-auto p-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m3.75 9v7.5m2.25-6.466a9.016 9.016 0 0 0-3.461-.203c-.536.072-.974.478-1.021 1.017a4.559 4.559 0 0 0-.018.402c0 .464.336.844.775.994l2.95 1.012c.44.15.775.53.775.994 0 .136-.006.27-.018.402-.047.539-.485.945-1.021 1.017a9.077 9.077 0 0 1-3.461-.203M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
</svg>


            </div>
            <div class="flex-1 p-4">
              <h3 class="mb-3 text-lg font-semibold">Automotive Service Dealerships</h3>
              <p class="text-gray-600 font-medium">Manage service appointments and maintenance reminders.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-3">
        <div class="p-7 bg-white border border-gray-900 rounded-3xl">
          <div class="flex flex-wrap -m-4">
            <div class="w-auto p-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
</svg>

            </div>
            <div class="flex-1 p-4">
              <h3 class="mb-3 text-lg font-semibold">Fitness Centers/Gyms</h3>
              <p class="text-gray-600 font-medium">Keep members updated on class schedules and special events.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-3">
        <div class="p-7 bg-white border border-gray-900 rounded-3xl">
          <div class="flex flex-wrap -m-4">
            <div class="w-auto p-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
</svg>

            </div>
            <div class="flex-1 p-4">
              <h3 class="mb-3 text-lg font-semibold">Beauty Salons and Spas</h3>
              <p class="text-gray-600 font-medium">Schedule treatments and promote special offers effortlessly.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-3">
        <div class="p-7 bg-white border border-gray-900 rounded-3xl">
          <div class="flex flex-wrap -m-4">
            <div class="w-auto p-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
</svg>


            </div>
            <div class="flex-1 p-4">
              <h3 class="mb-3 text-lg font-semibold">Real Estate Agencies</h3>
              <p class="text-gray-600 font-medium">Manage property viewings, client meetings, and important deadlines.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-3">
        <div class="p-7 bg-white border border-gray-900 rounded-3xl">
          <div class="flex flex-wrap -m-4">
            <div class="w-auto p-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5" />
</svg>

            </div>
            <div class="flex-1 p-4">
              <h3 class="mb-3 text-lg font-semibold">Educational Institutions</h3>
              <p class="text-gray-600 font-medium">Schedule classes, exams, and events for students.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full md:w-1/2 p-3">
        <div class="p-7 bg-white border border-gray-900 rounded-3xl">
          <div class="flex flex-wrap -m-4">
            <div class="w-auto p-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
</svg>


            </div>
            <div class="flex-1 p-4">
              <h3 class="mb-3 text-lg font-semibold">Event Planners</h3>
              <p class="text-gray-600 font-medium">Coordinate event schedules, client meetings, and vendor appointments.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full md:w-1/2 p-3">
        <div class="p-7 bg-white border border-gray-900 rounded-3xl">
          <div class="flex flex-wrap -m-4">
            <div class="w-auto p-4">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>


            </div>
            <div class="flex-1 p-4">
              <h3 class="mb-3 text-lg font-semibold">Financial Advisors</h3>
              <p class="text-gray-600 font-medium">Keep clients informed about meetings and important financial deadlines.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}


import React from 'react';

export default function IndexSectionCta5() {
    return (
        <React.Fragment>
            <>
                <section className="relative overflow-hidden bg-indigo-600 py-28">
  <img className="absolute bottom-0 right-0" src="flaro-assets/images/cta/gradient.svg" alt />
  <div className="container relative z-10 px-4 mx-auto">
    <div className="flex flex-wrap items-center -m-8">
      <div className="w-full p-8 md:w-1/2">
        <h2 className="mb-5 text-6xl font-bold leading-tight text-white md:text-7xl tracking-px-n">Ready to get started?</h2>
        <p className="font-medium text-white text-opacity-90 md:max-w-md">Experience a new level of efficiency and customer satisfaction with EveCal. It’s scheduling made simple, so you can focus on what you do best.</p>
      </div>
      <div className="w-full p-8 md:w-1/2">
        <div className="flex flex-wrap ml-auto -m-2 md:w-56">
          <div className="w-full p-2">
          </div>
          <div className="w-full p-2">
            <button className="inline-flex items-center justify-center w-full px-6 py-4 font-medium text-white transition duration-200 ease-in-out bg-transparent border border-white border-opacity-20 hover:border-opacity-40 rounded-xl focus:ring focus:ring-white focus:ring-opacity-40 hover:bg-black hover:bg-opacity-5" type="button">
              <svg className="mr-2.5" width={19} height={18} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.75 3.75C2.75 2.92157 3.42157 2.25 4.25 2.25H6.70943C7.03225 2.25 7.31886 2.45657 7.42094 2.76283L8.5443 6.13291C8.66233 6.48699 8.50203 6.87398 8.1682 7.0409L6.47525 7.88737C7.30194 9.72091 8.77909 11.1981 10.6126 12.0247L11.4591 10.3318C11.626 9.99796 12.013 9.83767 12.3671 9.9557L15.7372 11.0791C16.0434 11.1811 16.25 11.4677 16.25 11.7906V14.25C16.25 15.0784 15.5784 15.75 14.75 15.75H14C7.7868 15.75 2.75 10.7132 2.75 4.5V3.75Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span>Contact Us</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}


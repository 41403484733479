import React from 'react';

export default function IndexSectionHowItWorks2() {
    return (
        <React.Fragment>
            <>
                <section className="py-32 overflow-hidden bg-white">
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap -m-8 lg:items-center">
      <div className="w-full p-8 md:w-1/2">
        <img className="mx-auto transition duration-1000 ease-in-out transform hover:-translate-y-4" src="screenshot.png" alt />
      </div>
      <div className="w-full p-8 md:w-1/2">
        <h3 className="mb-20 text-6xl font-semibold leading-tight md:text-6xl font-heading tracking-px-n md:max-w-lg">EveCal is a subscription-based calendar service that integrates seamlessly with your clients' existing calendar apps </h3>
        <div className="flex flex-wrap -m-1.5">
          <div className="w-full p-1.5">
            <div className="flex flex-wrap -m-6">
              <div className="w-auto p-6">
                <div className="relative w-10 h-10 mb-3 text-lg font-bold text-white bg-indigo-600 rounded-full">
                  <img className="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt />
                  <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">1</span>
                </div>
                <img className="relative left-3" src="flaro-assets/images/how-it-works/line.svg" alt />
              </div>
              <div className="flex-1 p-6">
                <div className="md:max-w-xs">
                  <h3 className="mb-3 text-2xl font-semibold leading-snug">Create Personalized Calendars</h3>
                  <p className="font-medium leading-relaxed text-gray-700">For each of your clients, create a custom calendar outlining their appointments, important dates, and reminders.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full p-1.5">
            <div className="flex flex-wrap -m-6">
              <div className="w-auto p-6">
                <div className="relative w-10 h-10 mb-3 text-lg font-bold text-white bg-indigo-600 rounded-full -left-1">
                  <img className="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt />
                  <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">2</span>
                </div>
                <img className="relative left-3" src="flaro-assets/images/how-it-works/line2.svg" alt />
              </div>
              <div className="flex-1 p-6">
                <div className="md:max-w-xs">
                  <h3 className="mb-3 text-2xl font-semibold leading-snug">Share and Subscribe</h3>
                  <p className="font-medium leading-relaxed text-gray-700">Share a unique link with your clients. Once they subscribe, all future appointments and updates automatically appear on their personal calendars.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full p-1.5">
            <div className="flex flex-wrap -m-6">
              <div className="w-auto p-6">
                <div className="relative w-10 h-10 mb-3 text-lg font-bold text-white bg-indigo-600 rounded-full left-5">
                  <img className="absolute top-0 left-0" src="flaro-assets/images/how-it-works/gradient.svg" alt />
                  <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">3</span>
                </div>
              </div>
              <div className="flex-1 p-6">
                <div className="md:max-w-xs">
                  <h3 className="mb-3 text-2xl font-semibold leading-snug">Engage and Inform</h3>
                  <p className="font-medium leading-relaxed text-gray-700">Add promotions, advertisements, and specific reminders directly to these calendars to keep your clients informed and engaged.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

